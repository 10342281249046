import { LaborOrder } from "src/app/core/interfaces/labor-order.interface";
import { AdminPagesStateModel } from "src/app/features/admin/admin.state";
import { NavigationItem } from "src/app/shared/interfaces/navigation-item.interface";
import { SelectOption } from "src/app/shared/interfaces/select-option.interface";
import { TimeSheet } from "../../../../core/interfaces/timesheet.interface";
import { TotalHoursValue } from "../../interfaces/total-hours-values.model";

export class TimeheetsStateModel extends AdminPagesStateModel {
  items: TimeSheet[];
  selectedItem?: any;
  timesheetRows?: any;

  removing: boolean;
  updating: boolean;
  adding: boolean;
  
  errorUpdating: any;
  errorLoading: boolean;
  rowAdded: boolean;
  mappedTimesheet?: any;
  calculatedTotalAmount: any;
  hourRateTotals: TotalHoursValue;
  totalTimesheets: number;
  timeSheetRowErros?: any;
  
  selectedLaborOrder?: any;
  itemsByLaborOrder?: LaborOrder[];

  filterByOrder: any;
  filterByTimesheet: any;

  timesheetTabs: NavigationItem[];
  currentGang: string;

  timeKeepers: SelectOption[];
}